import { render, staticRenderFns } from "./_.vue?vue&type=template&id=5dcfeda2&scoped=true&"
import script from "./_.vue?vue&type=script&lang=js&"
export * from "./_.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dcfeda2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtDynamic: require('E:/JenkinsPyWorkspace/Jobs/IntegrationsDeploy/Projects/CPv3/MultiSiteClient/node_modules/@blokwise/dynamic/src/components/Dynamic.vue').default})
